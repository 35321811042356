.loginContainer{
    background-color: rgb(247 248 249);
    box-shadow: rgb(204, 219, 232) 3px 3px 12px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 12px 1px inset;
    padding: 15px;
    border-right: 1px solid #193C34 ;
    border-bottom: 1px solid #193C34 ;
}

.loginDiv{
  height: 100vh;
  display: flex;
  align-items: center;
  padding: 10px !important;
}