.App {
  text-align: center;
}

body {
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Mui-focused{
  color: black;
}

header{
  background-color: #EBEDE1 !important;
  color: black;
}

/* .css-2dum1v-MuiDrawer-docked .MuiDrawer-paper{
  background-color: #193C34  !important;
}

.css-1hsz34s-MuiDrawer-docked .MuiDrawer-paper {
  background-color: #193C34  !important;
  color: white;
} */

/* .css-2dum1v-MuiDrawer-docked .MuiDrawer-paper svg{
  color: white;
}

.css-1hsz34s-MuiDrawer-docked .MuiDrawer-paper svg {
  color: white;
} */

.css-1m5i5w0-MuiButtonBase-root-MuiListItemButton-root{
  padding: 5px;
}
.css-1m5i5w0-MuiButtonBase-root-MuiListItemButton-root:hover{
background-color: #76A238  !important;
}


.card{
  padding: 10px;
  border-radius: 0;
  border : none;
  border-left: 3px solid #333955;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
@media (max-width : 500px) {
  .dashboard-img{
    display: none;
  }
  .css-8rwzss,.css-189bs4g{
    overflow-x: hidden;
  }
  /* .css-b9xjnq-MuiChartsAxis-root.MuiChartsAxis-left .MuiChartsAxis-tickLabel{
    text-anchor: start !important;
  } */
}




